.pd-background {
  &-transparent {
    background-color: transparent !important;
  }
  &-primary {
    background-color: #eff0f8 !important;
  }
  &-button {
    background: linear-gradient(267.78deg, #45abff -15.65%, #9a70ff 117.91%);
    &:hover {
      background: linear-gradient(
        267.78deg,
        #45abff -15.65%,
        #9a70ff 117.91%
      ) !important;
      color: #000 !important;
    }
  }
}
