@import '../../scss/variables/index.scss';

// button styling
.pd-button {
  &.ant-btn {
    border-radius: 8px;

    &-primary {
      border: none;
      font-weight: 600;
      color: $pd-color-white;
    }
    &-default {
      border-radius: 8px;
      font-weight: 600;
      background-color: transparent;
      &:focus,
      &:active {
        background-color: transparent;
      }
    }
    &-link {
      font-weight: 600;
    }
  }

  &-color-blue {
    &.ant-btn {
      &-primary {
        background-color: $pd-color-blue;
      }
      &-default {
        border: thin solid $pd-color-blue;
        color: $pd-color-blue;
        &:hover {
          background-color: transparent;
          color: $pd-color-blue;
        }
      }
      &-link {
        color: $pd-color-blue;
      }
    }
  }

  &-color-black {
    &.ant-btn {
      &-primary {
        background-color: $pd-color-black;
        &:hover,
        &:active,
        &:focus {
          background-color: $pd-color-black;
        }
      }
      &-default {
        border: thin solid $pd-color-black;
        color: $pd-color-black;
        &:hover,
        &:active,
        &:focus {
          color: $pd-color-black;
          border-color: $pd-color-black;
          background-color: transparent;
        }
      }
      &-link {
        color: $pd-color-black;
        &:hover,
        &:active,
        &:focus {
          color: $pd-color-black;
        }
      }
    }
  }

  &-color-gray {
    &.ant-btn {
      &-primary {
        background-color: $pd-color-gray;
        &:hover,
        &:active,
        &:focus {
          background-color: $pd-color-gray;
        }
      }
      &-default {
        border: thin solid $pd-color-gray;
        color: $pd-color-gray;
      }
      &-link {
        color: $pd-color-gray;
        &:hover,
        &:active,
        &:focus {
          color: $pd-color-gray;
        }
      }
    }
  }

  &-color-white {
    &.ant-btn {
      &-primary {
        color: $pd-color-black;
        background-color: $pd-color-white;
        &:hover,
        &:active,
        &:focus {
          background-color: $pd-color-white;
          color: $pd-color-black;
        }
      }
      &-default {
        border: thin solid $pd-color-gray;
        color: $pd-color-black;
      }
      &-link {
        color: $pd-color-black;
        &:hover,
        &:active,
        &:focus {
          color: $pd-color-black;
        }
      }
    }
  }
}
