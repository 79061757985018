.pd-button {
  &-nav {
    padding: 8px 24px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  &-primary {
    padding: 12px 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &-secondary {
    padding: 14px 24px;
  }
}
