.block {
  display: block;
}

.flex {
  display: flex;

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &.center {
    justify-content: center;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-evenly {
    justify-content: space-evenly;
  }

  &.end {
    justify-content: end;
  }

  &.items-center {
    align-items: center;
  }

  &.items-start {
align-items: flex-start;
  }

  &.items-end {
    align-items: flex-end;
  }
}
