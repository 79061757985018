@import '../../../scss/variables/index.scss';

.pd-shared-modal-password {
  padding: $spacing-lg 0;
  position: relative;
  .subtitle {
    text-align: center;
    margin-bottom: $spacing-lg;
  }
  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    .form-wrapper {
      align-self: center;
      max-width: 340px;
      width: 100%;
      .btn-finish {
        text-align: center;
        margin-bottom: 0;
      }
    }
  }
}

// .pd-guest-modal-password {
//   &-title {
//     margin-top: $spacing-xl;
//   }
// }

// #password-form {
//   margin-top: $spacing-xl;
// }
