@import '../../scss/variables/index.scss';

.pd-antd-input .ant {
  &-input {
    &-group > .ant-input:first-child,
    .ant-input-group-addon:first-child {
      border-radius: $pd-border-radius-md 0 0 $pd-border-radius-md;
    }
  }
}

.pd-antd-input.ant {
  &-input {
    border-radius: $pd-border-radius-md;
    &-number {
      width: 100%;
      border-radius: $pd-border-radius-md;
    }
    &-search
      > .ant-input-group
      > .ant-input-group-addon:last-child
      > .ant-input-search-button {
      border-radius: 0 $pd-border-radius-md $pd-border-radius-md 0;
      border-left: none;
    }
    &-affix-wrapper {
      border-radius: $pd-border-radius-md;
    }
  }
}
