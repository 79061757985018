@import './scss/utils/index';
@import './scss/variables/index';
body {
  font-family: 'Poppins', sans-serif;
}

.pd-cms {
  min-height: 100vh;
}

// a {
//   color: #697586;
// }
