.w {
  &-full {
    width: 100%;
  }

  &-10 {
    width: 10%;
  }

  &-20 {
    width: 20%;
  }

  &-30 {
    width: 30%;
  }

  &-40 {
    width: 40%;
  }

  &-50 {
    width: 50%;
  }

  &-60 {
    width: 60%;
  }

  &-70 {
    width: 70%;
  }

  &-80 {
    width: 80%;
  }

  &-90 {
    width: 90%;
  }
}
