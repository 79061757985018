@import '../../../../scss/variables/index.scss';

.row-card {
  border: thin solid $pd-color-light-gray;
  background-color: $pd-color-lighten-gray;
  border-radius: $pd-border-radius-md;
  padding: $spacing-md;
}

.card-counter {
  border: 2px solid $pd-color-light-gray;
  padding: $spacing-md;
  border-radius: $pd-border-radius-md;
  max-width: 250px;
  width: 100%;
}

.button-wrapper {
  border: 2px solid $pd-color-light-gray;
  border-radius: $pd-border-radius-md;

  .button-detail {
    padding: $spacing-sm;
    cursor: pointer;
    border-right: 1px solid $pd-color-light-gray;
  }
}
