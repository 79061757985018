@import '../../../../scss/variables/index.scss';
.ant-alert {
  &.ant-alert-info {
    background-color: $pd-color-blue !important;
    color: white;

    .ant-alert-message {
      color: white;
    }

    .ant-alert-icon {
      color: white;
    }
  }
}

.steps-wrapper {
  border: 1px solid $pd-color-light-gray;
  border-radius: $pd-border-radius-md;
  padding: $spacing-lg;
  margin-bottom: 2rem;
}
