.font {
  &-bold {
    font-weight: bold;
  }
  &-500 {
    font-weight: 500;
  }
  &-400 {
    font-weight: 400;
  }
  &-300 {
    font-weight: 300;
  }
}
