@import './src/scss/mixins/index.scss';
.pd-login {
  @include abs-center;

  height: 90vh;

  .login-wrapper {
    display: flex;
    flex-direction: row-reverse;
    width: 70%;
    height: 70vh;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 4px 4px 24px 4px rgba(206, 206, 206, 0.4);

    .login-content {
      flex-grow: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .login-bg {
      flex-grow: 1;
      background-image: url('../../assets/background/login.png');
      background-size: cover;
      background-position: left;
      width: 100%;
      overflow: hidden;
    }
  }

  .login-form {
    width: 100%;
    max-width: 300px;

    &-button {
      width: 100%;
    }
  }
}
