@import './src/scss/variables/index.scss';

.pd-title {
  &-color {
    &-black.ant-typography {
      color: $pd-color-black;
    }
    &-gray.ant-typography {
      color: $pd-color-darker-gray;
    }
    &-blue.ant-typography {
      color: $pd-color-blue;
    }
    &-white.ant-typography {
      color: $pd-color-white;
    }
  }

  &-align {
    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  &-weight {
    &-normal {
      font-weight: 400;
    }
    &-bold {
      font-weight: bold;
    }

    &-bolder {
      font-weight: bolder;
    }
  }

  &-margin {
    &-none {
      &.ant-typography {
        margin-bottom: 0;
      }
    }
    &-large {
      &.ant-typography {
        margin-bottom: $spacing-lg;
      }
    }
    &-xlarge {
      &.ant-typography {
        margin-bottom: $spacing-xl;
      }
    }
  }

  &-size {
    &-extra-small {
      font-size: calc(#{$pd-font-size-sm} * 1.25)!important;
    }
    &-small {
      font-size: calc(#{$pd-font-size-md} * 1.5) !important;
    }
    &-normal {
      font-size: $pd-font-size-lg !important;
    }
    &-large {
      font-size: calc(#{$pd-font-size-lg} * 1.5) !important;
    }
  }
}
