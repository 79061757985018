
@import './src/scss/variables/index.scss';

aside.pd-cms-sidebar {
  -ms-overflow-style: none;
  background: $pd-color-white;
  overflow: auto;
  padding: 0 $spacing-md;
  scrollbar-width: none;
  text-transform: uppercase;

  &::-webkit-scrollbar {
    display: none;
  }

  .ant {
    &-menu {
      background: inherit;
      border-right: none;
      color: $pd-color-dark-gray;
      text-transform: capitalize;
      &-item {
        border-radius: $pd-border-radius-md;

        a,
        img {
          &:hover {
            color: black;
          }
        }
      }
      &-submenu {
        border-radius: $pd-border-radius-md;
      }
      &-item::after {
        border-right: none;
      }
      &-submenu {
        &-title:hover,
        &-selected,
        &-active,
        &-arrow {
          color: $pd-color-black;
        }
      }
    }
    &-menu:not(.ant-menu-horizontal) {
      .ant-menu-item-selected {
        background-color: $pd-color-gray;
        a {
          color: $pd-color-black;
        }
        img {
          color: $pd-color-black;
        }
      }
    }
    &-menu-item-only-child {
      a {
        color: $pd-color-dark-gray;
        &:hover {
          color: $pd-color-black;
        }
      }
    }
    &-typography {
      padding: 24px;
    }
  }

  .user-badge {
    a {
      color: $pd-color-black;
    }
  }
}
