@import '../../scss/variables/index.scss';

.pd-modal {
  .ant {
    &-modal {
      &-content {
        border-radius: $pd-border-radius-md;
      }
    }
  }
}

.pd-guest-modal {
  .ant {
    &-modal {
      max-width: 380px;
      width: 100%;
      &-content {
        border-radius: $pd-border-radius-md;
      }
    }
  }
  &-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 384px;
  }

  &-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 166px;
  }

  .ant-modal-body {
    min-height: 360px;
  }

  .ant-modal-content {
    overflow: hidden;
  }

  .ant-modal-close-x {
    font-size: $pd-font-size-md;
    margin: $spacing-sm;
  }
}
