$pd-color-white: #ffffff;
$pd-color-gray: #cccccc;
$pd-color-dark-gray: #777777;
$pd-color-darker-gray: #444444;
$pd-color-black: #222222;

//dashboard color
$pd-color-blue: #44abff;
$pd-color-light-blue: #6fbbfa;
$pd-color-light-gray: #e3e8ef;
$pd-color-lighten-gray: #f6f6f6;
// color theme
$primary-color: $pd-color-darker-gray;
$secondary-color: lighten($primary-color, 20);

//web landing
$pd-color-date: #8c8c8c;
$pd-color-white: #ffffff;
$pd-color-light-green: #d9f4ad;
$pd-color-green: #46b51f;
$pd-color-light-yellow: #f4e9ad;
$pd-color-dark-yellow: #b59d1f;

// color theme
$primary-background-color: #e5e5e5;
$sidebar-active-color: rgba(76, 111, 255, 0.13);
$pd-menu-color: #425466;
