@import './src/scss/variables/index.scss';

.pd-antd-header {
  background-color: white !important;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .title-page {
    text-transform: capitalize;
  }

  &.ant {
    &-typography {
      text-transform: capitalize;
    }
    &-layout-header {
      padding-left: $spacing-lg;
    }
  }
}
