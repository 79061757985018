@import '../../scss/variables/index';
.nav-content-wrapper {
  display: flex;
  align-items: center;

  .ant-badge {
    margin-right: 1rem;

    .notif-img {
      cursor: pointer;
    }
  }

  .profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    .ant-avatar {
      margin-right: 0.5rem;
    }

    div {
      margin-right: 0.5rem;
    }

    &-menu {
      position: absolute;
      top: 100%;
      background-color: $pd-color-white;
      border: 1px solid #d9d9d9;
      box-shadow: -2px 2px 4px rgba(16, 24, 40, 0.06),
        0px 4px 8px rgba(16, 24, 40, 0.1);
      border-radius: 8px;
      padding: $spacing-xs 0 0 $spacing-xs;

      display: flex;
      flex-direction: column;
      z-index: 10;
      min-width: max-content;

      .option {
        line-height: 40px;
        display: inline;
        border-bottom: 1px solid #d9d9d9;
        cursor: pointer;
        padding: $spacing-xs;

        img {
          margin-right: 0.5rem;
        }

        a {
          color: #697586;
          &:hover {
            color: $pd-color-black;
          }
        }
      }
    }
  }
}

.ant-popover {
  .ant-popover-content {
    .ant-popover-inner-content {
      min-height: 200px;
    }
  }
}
