@import '../../../../scss/variables/index.scss';

.card-counter {
  border: 1px solid $pd-color-light-gray;
  padding: $spacing-md;
  border-radius: $pd-border-radius-md;
  max-width: 250px;
  width: 100%;
}

.balance-wrapper {
  border: 1px solid $pd-color-light-gray;
  border-radius: $pd-border-radius-md;
}
