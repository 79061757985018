// @import 'scss/variables/color';

.pd-text {
  // &-primary {
  //   color: $pd-color-blue;
  // }
  &-white {
    color: white;
  }
  &-explore {
    color: #464848;
    font-weight: 600;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
  }
  &-primary {
    font-size: 32px;
    line-height: 48px;
    color: #1a1a1a;
  }
  &-secondary {
    font-size: 18px;
    line-height: 27px;
    color: #364152;
  }
  &-price {
    font-size: 14px;
    line-height: 21px;
    color: #464848;
  }
  &-body-card {
    font-size: 14px;
    line-height: 21px;
    color: #697586;
  }
  &-body-card-2 {
    font-size: 14px;
    line-height: 21px;
    color: #121926;
  }
}
