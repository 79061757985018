@import './src/scss/variables/index';

.pd-cms {
  &-content {
    background-color: $pd-color-white;
    padding: $spacing-lg 0;
    height: 100%;
    padding: $spacing-lg;
    .ant {
      &-legacy-form-item-label,
      &-form-item-label {
        label {
          color: $pd-color-black;
          font-weight: 400;
        }
      }
    }
    .section-title {
      color: $pd-color-black;
    }
  }
}
