.h {
  &-screen {
    height: 100vh;
  }
  &-full {
    height: 100%;
  }
  &-nav {
    height: 64px;
  }
  &-input {
    height: 40px;
  }
  &-button {
    height: 40px;
  }
  &-button-primary {
    height: 48px;
  }
}
